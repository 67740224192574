import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import PrivateRoute from "./utils/PrivateRoute";
import Search from "./pages/Search";
import Logout from "./pages/Logout";

const AppRoutes = ({ setIsLoggedIn }) => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
    <Route
      path="/admin"
      element={
        <PrivateRoute>
          <Admin />
        </PrivateRoute>
      }
    />
    <Route
      path="/search"
      element={
        <PrivateRoute>
          <Search />
        </PrivateRoute>
      }
    />
    <Route
      path="/logout"
      element={
        <PrivateRoute>
          <Logout setIsLoggedIn={setIsLoggedIn} />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default AppRoutes;
