import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const SearchForm = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const theme = useTheme();

const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    if (searchQuery.trim() !== '') {
        onSearch(searchQuery);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        border: `1px solid ${theme.palette.common.black}`,
        maxWidth: 800,
        margin: 'auto',
        marginTop: theme.spacing(4),
      }}
    >
      <TextField
        value={searchQuery}
        onChange={handleInputChange}
        variant="standard"
        label="keresés a cikkekben"
        placeholder="keresett kifejezés"
        fullWidth
        sx={{
          marginRight: theme.spacing(2),
          backgroundColor: theme.palette.background.paper,
          padding: '5px',
          borderRadius: theme.shape.borderRadius,
        }}
      />
      <Button
        variant="contained"
        onClick={handleSearch}
        sx={{
          color: theme.palette.button.primary,
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
              backgroundColor: theme.palette.button.hover,
          },
        }}
      >
        Keresés
      </Button>
    </Box>
  );
};

export default SearchForm;

