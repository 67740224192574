import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

const Home = () => {
  const theme = useTheme();
  return (
    <Box sx={{ padding: theme.spacing(2), maxWidth: 800, margin: 'auto' }}>
      kezdolap
    </Box>
  );
};

export default Home;
