import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { maxWidth } from "@mui/system";

const Header = ({ isLoggedIn }) => {
  const [scrolled, setScrolled] = useState(false);
  const theme = useTheme();

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: scrolled ? theme.shadows[2] : "none",
        borderBottom: scrolled ? "1px solid #ccc" : "none",
        transition:
          "background-color 0.3s, border-bottom 0.3s, box-shadow 0.3s",
        width: "100%",
      }}
    >
      <Toolbar sx={{ justifyContent: "center" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Typography variant="h4">Bitraktár</Typography>
          <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <Typography component={Link} to="/" className="headerLink">
              kezdőlap
            </Typography>
            {!isLoggedIn ? (
              <Typography component={Link} to="/login" className="headerLink">
                bejelentkezés
              </Typography>
            ) : (
              <>
                <Typography
                  component={Link}
                  to="/search"
                  className="headerLink"
                >
                  keresés
                </Typography>
                <Typography component={Link} to="/admin" className="headerLink">
                  admin
                </Typography>
                <Typography
                  component={Link}
                  to="/logout"
                  className="headerLink"
                >
                  kijelentkezés
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
