import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AxiosInstance from '../utils/AxiosInstance';
import LoginForm from '../components/LoginForm';
import ErrorMessage from '../components/ErrorMessage';

const Login = ({ setIsLoggedIn }) => {
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (credentials) => {
    try {
      const response = await AxiosInstance.post('/authenticate', credentials);
      const { token } = response.data;
      localStorage.setItem('token', token);
      setIsLoggedIn(true);
      navigate('/');
    } catch (err) {
      setError('rossz felhasználónév vagy jelszó');
      setOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <LoginForm onSubmit={handleLogin} />
      <ErrorMessage open={open} message={error} handleClose={handleClose} />
    </div>
  );
};

export default Login;

