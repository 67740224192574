import React, { useState } from "react";
import MagazineList from "../components/MagazineList.js";
import Box from "@mui/material/Box";

const Admin = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
      }}
    >
      <MagazineList />
    </Box>
  );
};

export default Admin;
