import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#black', 
    },
    button: {
      primary: 'c3c3c3',
      hover: '#818181',
    },
  },
  shape:  {
    borderRadius: 2,
  },
  shadows: [
    'none',
    '0px 1px 3px rgba(0,0,0,0.12)',
    '0px 1px 2px rgba(0,0,0,0.24)',
    '0px 3px 4px rgba(0,0,0,0.2)',
    '0px 4px 5px rgba(0,0,0,0.2)',
  ],
  typography: {
    subtitle1: {
      fontFamily: 'ProFontMonoRegular',
      color: 'black',
    },
    body1: {
      fontFamily:'Times New Roman',
      color: 'black',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
          root: {
            border: '0px',
          },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: 'ProFontMonoRegular',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid',
        },
      },
    },
    MuiTypography: {
      styleOverridese: {
        root: {
          '&.headerLink': {
            textDecoration: 'none',
            color: '#000000',
            '&hover': {
              color: '#ffffff',
              backgroundColor: '#818181',
            },
          },
        },
      },
    },
  },
});

export default theme;
