import React, { useEffect, useState } from "react";
import AxiosInstance from "../utils/AxiosInstance";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { Paper, IconButton } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { borderRadius } from "@mui/system";

const MagazineList = () => {
  const theme = useTheme();
  const [magazines, setMagazines] = useState([]);

  useEffect(() => {
    const fetchMagazines = async () => {
      try {
        const magazinesResponse = await AxiosInstance.get("/magazines");
        setMagazines(magazinesResponse.data);
      } catch (error) {
        console.error("XXXXXXXXXXXXXXXX", error);
      }
    };

    fetchMagazines();
  }, []);

  return (
    <Box sx={{ padding: theme.spacing(2), maxWidth: 800, margin: "auto" }}>
      {magazines.map((magazine) => (
        <Paper
          key={magazine.id}
          sx={{
            marginBottom: theme.spacing(2),
            borderColor: "black",
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[1],
          }}
        >
          <Box
            sx={{
              // background: 'linear-gradient(to right, #1d1c75, #2596be)',
              paddingLeft: "10px",
              paddingRight: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                margin: theme.spacing(1),
                color: theme.palette.text.primary,
                flexGrow: 1,
              }}
            ></Typography>
            {magazine.title}
            <IconButton
              sx={{
                borderRadius: 0,
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              sx={{
                borderRadius: 0,
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
              }}
            >
              <Delete />
            </IconButton>
          </Box>
        </Paper>
      ))}
    </Box>
  );
};

export default MagazineList;
