import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import AxiosInstance from './AxiosInstance';

const PrivateRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // Initially null to differentiate loading state

    useEffect(() => {
        const token = localStorage.getItem('token');
        
        if (!token) {
            setIsAuthenticated(false);
            return;
        }

        const checkAuth = async () => {
            try {
                // Send a request to validate the token
                const response = await AxiosInstance.get('/verify', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.status === 200) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                setIsAuthenticated(false);
                localStorage.removeItem('token');
            }
        };

        checkAuth();
    }, []);

    if (isAuthenticated === null) {
        //TODO loading indicator here
        return null;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
