import axios from 'axios';

const AxiosInstance = axios.create({
    baseURL: 'https://bitraktar.xyz',
    headers: {
        'Content-Type': 'application/json',
    },
});

AxiosInstance.interceptors.request.use(
    (config) => {
        if (!config.url.includes('/authenticate')) {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default AxiosInstance;

