import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const SearchResults = ({ results, query }) => {
  const theme = useTheme();
  const [expandedResultId, setExpandedResultID] = useState(null);
    
  const highlightText = (text, query) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) => part.toLowerCase() === query.toLowerCase() ? <mark key={index}>{part}</mark> : part );
  }; 

  const handleResultClick = (id) => {
    setExpandedResultID(expandedResultId === id ? null : id);
  };
  
  return (
    <Box sx={{ padding: theme.spacing(2), maxWidth: 800, margin: 'auto' }}>
        {results.length > 0 ? (
          results.map((result) => (
            <Paper
              key={result.id}
              sx={{
                  marginBottom: theme.spacing(2),
                  borderColor: 'black',
                  borderRadius: theme.shape.borderRadius,
                  boxShadow: theme.shadows[1],
              }}
              onClick={() => handleResultClick(result.id)}
            >  
              <Box
                sx={{
                  // background: 'linear-gradient(to right, #1d1c75, #2596be)',
                  borderBottom: '1px solid black',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle1" sx={{ color: theme.palette.text.header, flexGrow: 1 }}>
                    {result.title}
                </Typography>
                <Typography variant="body2" sx={{ color: theme.palette.text.header }}>
                      év : {result.issueYear} | szám : {result.issueNumber} | oldal : {result.pageNumber} 
                  </Typography>
              </Box>
              <Typography variant="body1" sx={{ margin: theme.spacing(1), color: theme.palette.text.primary }}>
                  {expandedResultId === result.id ? highlightText(result.text, query) : highlightText(result.snippet, query)}
              </Typography>
            </Paper>
          ))
        ) : (
          <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
              nincs talalat
          </Typography>
        )}
    </Box>
  );
};

export default SearchResults;
