import React, { useState } from 'react';
import SearchForm from '../components/SearchForm.js';
import SearchResults from '../components/SearchResults.js'
import AxiosInstance from '../utils/AxiosInstance.js';
import Box from '@mui/material/Box';

const Search = () => {
  const [results, setResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  
  const handleSearch = (query) => {
    setSearchQuery(query);

    AxiosInstance.get(`/search/text?query=${query}`)
    .then((response) => {
        setResults(response.data);
        console.log(response.data)
    })
    .catch((err) => {
        console.error(err);
    });

  };

  return (
   <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
    <SearchForm onSearch={handleSearch} />
    <SearchResults results={results} query={searchQuery}/>
  </Box>
  );
};

export default Search; 
