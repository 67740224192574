import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { ThemeProvider, CssBaseline, Box } from '@mui/material';
import Header from './components/Header';
import AppRoutes from './routes';
import theme from './styles/theme';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  return(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box sx={{
          justifyContent: 'flex-start',
          paddingTop: '64px',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '800px',
          }}
        >
          <Header isLoggedIn={isLoggedIn}/>       
        </Box>
        <Box sx={{ flexGrow: 1 }}
        >
          <AppRoutes setIsLoggedIn={setIsLoggedIn}/>
        </Box>
      </Router>
    </ThemeProvider>
  );
};
export default App;
